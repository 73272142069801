import React from "react";

import { Container, Wrapper } from "../../../components/util";
import {
  IntegrationsPageHeader,
  WhatIsIntegration,
  WhatIsInvoxy,
  IntegrationsNav,
} from "../../../components/site";
import Image from "../../../components/image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import Logo from "../../../images/logos/Other/EmploymentHero_Full_Black.svg";

const EmploymentHero = (props) => (
  <Layout>
    <Seo
      title="Employment Hero Integration | Karmly"
      description="Send hours, work and pay rates for each Resource from Karmly to Employment Hero for Australian payroll processing."
      pathname={props.location.pathname}
    />
    <IntegrationsNav />
    <IntegrationsPageHeader
      category="AUSTRALIAN PAYROLL"
      title="Connect Karmly with Employment Hero"
      description="Automatically send pay runs to your payroll software."
    />
    <Container>
      <Wrapper>
        <h4 className="-textCenter">
          Send hours, work and pay rates for each Resource from Karmly to
          Employment Hero for Australian payroll processing.
        </h4>
        <Image
          filename="EmploymentHero_Feature.png"
          alt="Employment Hero + Karmly Integration"
          addShadow
        />
      </Wrapper>
    </Container>
    <WhatIsInvoxy />
    <WhatIsIntegration
      title="What is Employment Hero?"
      description="Employment Hero is a cloud-based workforce management and payroll platform."
      integration="Employment Hero"
      link="https://employmenthero.com/"
      logo={Logo}
      details={[
        "Employment Hero automates the flow of data from the employee, through to payroll and back to the business in a single integrated platform. Employment Hero saves you time and money by combining leave management, payroll and reporting in a complete end-to-end solution.",
        "Employment Hero increases payroll compliance and accuracy through pre-built Awards and support for custom EBA’s, SBR integration and paperless employee onboarding. Using our mobile and iPad apps, employees and employers can easily access payroll and employee information, anytime, anywhere.",
      ]}
    />
  </Layout>
);

export default EmploymentHero;
